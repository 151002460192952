/* Container general */
.league-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  /* Stil pentru fiecare eveniment */
  .event {
    cursor: pointer;
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  /* Schimbare stil la hover */
  .event:hover {
    background-color: #f1f1f1;
    transform: translateY(-3px); /* Un mic efect de "lift" la hover */
  }
  
  /* Titlul evenimentului */
  .event h3 {
    margin: 0 0 10px;
    font-size: 1.4rem;
    color: #333;
  }
  
  /* Informații eveniment */
  .event p {
    margin: 5px 0;
    font-size: 1rem;
    color: #666;
  }
  .event-thumb {
    width: 100%; /* sau orice dimensiune dorești */
    max-width: 400px; /* limitează lățimea maximă */
    height: auto; /* păstrează proporțiile imaginii */
    margin-top: 10px; /* margine de separare de restul conținutului */
    border-radius: 8px; /* opțional, colțuri rotunjite */
  }
  
  
  /* Responsivitate pentru mobil (ecran mic) */
  @media (max-width: 600px) {
    .league-details {
      padding: 10px;
    }
  
    .event {
      padding: 10px;
    }
  
    .event h3 {
      font-size: 1.2rem;
    }
  
    .event p {
      font-size: 0.9rem;
    }
  }
  
  /* Responsivitate pentru tablete */
  @media (min-width: 601px) and (max-width: 1024px) {
    .league-details {
      padding: 15px;
    }
  
    .event {
      padding: 12px;
    }
  
    .event h3 {
      font-size: 1.3rem;
    }
  
    .event p {
      font-size: 1rem;
    }
  }
  
  /* Stiluri desktop */
  @media (min-width: 1025px) {
    .league-details {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Afișează evenimentele într-o grilă pe ecrane mari */
      gap: 20px;
    }
  
    .event h3 {
      font-size: 1.5rem;
    }
  
    .event p {
      font-size: 1.1rem;
    }
  }
  