/* Reset general */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    overflow-x: hidden;
  }
  
  /* Stiluri pentru header */
  header {
    display: flex;
    flex-direction: column; /* Afișează logo-ul deasupra sporturilor */
    justify-content: center;
    align-items: center;
    background-color: #1e1e1e;
    padding: 10px 20px;
    color: white;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo h1 {
    font-size: 2rem; /* Ajustează dimensiunea fontului */
    color: #61dafb;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px; /* Spațiu între logo și sporturi */
  }
  
  .menu-toggle {
    display: none;
    cursor: pointer;
    font-size: 2rem;
    background: none;
    border: none;
    color: white;
  }
  
  .menu-toggle:hover {
    color: #61dafb;
  }
  
  nav {
    display: flex;
    flex-wrap: wrap; /* Permite trecerea pe mai multe rânduri */
    justify-content: center; /* Centrează sporturile */
  }
  
  nav ul {
    display: flex;
    flex-wrap: wrap; /* Permite ca sporturile să fie afișate pe mai multe rânduri */
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  nav ul li {
    margin: 10px 10px; /* Spațiere între sporturi */
    font-size: 1rem;
    color: white;
    padding: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    background-color: #3b3b3b;
    min-width: 100px; /* Lățimea minimă pentru fiecare sport */
    text-align: center; /* Centrează textul în interiorul butonului */
  }
  
  nav ul li:hover {
    background-color: #61dafb;
    transform: scale(1.05);
    color: #1e1e1e;
  }
  
  /* Stiluri pentru ecrane mai mari */
  @media (min-width: 768px) {
    header {
      padding: 15px 30px;
    }
  
    .logo h1 {
      font-size: 2.4rem;
    }
  
    nav ul li {
      font-size: 1.2rem;
      margin-left: 20px;
    }
  }
  .sport-icon {
    width: 20px; /* Ajustează lățimea dorită pentru iconiță */
    height: auto; /* Înălțimea va fi ajustată automat */
    margin-right: 8px; /* Spațiu între iconiță și text */
    vertical-align: middle; /* Aliniază iconița cu textul */
  }
  
  /* Stiluri pentru mobil */
  @media (max-width: 767px) {
    nav {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 0;
      background-color: #1e1e1e;
      width: 100%;
      height: 100vh;
    }
  
    nav ul {
      flex-direction: column;
      width: 100%;
      text-align: center;
    }
  
    nav ul li {
      margin: 10px 0;
      font-size: 1.5rem;
    }
  
    .menu-toggle {
      display: block;
    }
  
    .menu-open nav {
      display: flex;
    }
  }
  