/* Stiluri pentru SportEvents */
.sport-events {
  padding: 20px; /* Padding general pentru componentă */
  background-color: #f0f0f0; /* Culoare de fundal deschisă */
  border-radius: 8px; /* Colțuri rotunjite */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Umbra */
}

.search-input {
  width: 100%; /* Lățimea completă a inputului */
  padding: 10px 15px; /* Padding interior pentru input */
  border: 1px solid #ccc; /* Bordură gri deschis */
  border-radius: 5px; /* Colțuri rotunjite */
  font-size: 16px; /* Dimensiunea fontului */
  transition: border-color 0.3s; /* Tranziție pentru schimbarea culorii bordurii */
  margin-bottom: 20px; /* Margine între input și ligile afișate */
}

.search-input:focus {
  border-color: #61dafb; /* Schimbă culoarea bordurii la focus */
  outline: none; /* Elimină conturul default */
  box-shadow: 0 0 5px rgba(97, 218, 251, 0.5); /* Umbra subtilă la focus */
}

.league {
  margin-bottom: 20px; /* Margine între ligile diferite */
  background-color: #ffffff; /* Fundal alb pentru fiecare ligă */
  border-radius: 5px; /* Colțuri rotunjite */
  padding: 10px; /* Padding intern */
  transition: transform 0.3s; /* Tranziție pentru efectul de hover */
}

.league:hover {
  transform: translateY(-2px); /* Efect de levitare la hover */
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Umbra mai puternică la hover */
}

.league-title {
  font-size: 1.5rem; /* Dimensiunea fontului pentru titlul ligii */
  color: #282c34; /* Culoarea textului */
  cursor: pointer; /* Cursorul devine pointer la hover */
  transition: color 0.3s; /* Tranziție pentru schimbarea culorii */
}

.league-title:hover {
  color: #61dafb; /* Schimbă culoarea titlului la hover */
}

/* Media query pentru tabletă și mobil */
@media (max-width: 768px) {
  .sport-events {
      padding: 15px; /* Ajustează paddingul pe dispozitive mai mici */
  }

  .league-title {
      font-size: 1.2rem; /* Dimensiunea fontului pentru titlul ligii pe mobil */
  }

  .search-input {
      font-size: 14px; /* Dimensiunea fontului pentru input pe mobil */
  }
}

@media (max-width: 480px) {
  .league-title {
      font-size: 1rem; /* Dimensiunea fontului pentru titlul ligii pe mobil mic */
  }
}
