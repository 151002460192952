/* Base styles */
.live-scores {
  max-width: 100%; /* Allow component to take full width */
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  border-radius: 8px; /* Rounded corners for a modern look */
  background-color: #fff; /* Background color for readability */
}

.sport-section {
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9; /* Light background for sections */
}

.sport-title {
  cursor: pointer;
  font-size: 1.5em;
  color: #007bff;
}

.competition-list {
  list-style: none;
  padding: 0;
}

.competition-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px; /* Add some padding */
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s;
}

.competition-item:hover {
  cursor: pointer;
  background-color: #f0f8ff;
}

.competition-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1; /* Allow details to grow */
}

.team-badge {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.team-name {
  margin: 0 10px; /* Reduce space for smaller screens */
  flex-grow: 1; /* Allow team name to grow */
}

.score {
  margin: 0 10px;
}

.league-name, .status, .progress {
  margin-left: 10px;
  font-size: 0.9em;
}

select {
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .live-scores {
      padding: 10px; /* Less padding on smaller screens */
  }

  .competition-item {
      flex-direction: column; /* Stack items vertically */
      align-items: flex-start; /* Align items to start */
  }

  .competition-details {
      justify-content: flex-start; /* Align to the start */
  }

  .team-name {
      margin: 5px 0; /* Margin for stacked items */
  }

  .score {
      margin: 5px 0; /* Margin for stacked items */
  }
}

@media (max-width: 480px) {
  .sport-title {
      font-size: 1.3em; /* Adjust font size for small screens */
  }

  select {
      font-size: 14px; /* Adjust font size for smaller devices */
  }
}
