.event-details {
    max-width: 800px; /* Lățimea maximă pentru detaliile evenimentului */
    margin: 20px auto; /* Centrarea pe pagină */
    padding: 20px;
    background-color: #ffffff; /* Fundal alb pentru contrast */
    border-radius: 8px; /* Colțuri rotunjite */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Umbră pentru un efect 3D */
  }
  
  .event-details h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333; /* Culoarea textului */
  }
  
  .event-details p {
    font-size: 1em;
    margin: 5px 0;
    color: #666; /* Culoare gri pentru text */
  }
  
  .team-details {
    display: flex;
    justify-content: space-between; /* Distribuirea echipelor pe lățimea containerului */
    margin-top: 20px;
  }
  
  .team {
    text-align: center; /* Centrarea textului în echipă */
  }
  
  .team-badge {
    width: 80px; /* Ajustează dimensiunea emblemei */
    height: 80px; /* Ajustează dimensiunea emblemei */
    object-fit: contain; /* Menține raportul de aspect al imaginii */
    margin-bottom: 10px; /* Spațiere între emblemă și numele echipei */
  }
  
  /* Stil modern pentru tabelul cu clasamentul ligii */
.league-table {
  width: 100%; /* Tabelul ocupă întreaga lățime */
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #ffffff; /* Fundal alb */
  border-radius: 8px; /* Colțuri rotunjite */
  overflow: hidden; /* Pentru a evita depășirile */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Umbră ușoară pentru efect */
}

.league-table th {
  background-color: #007bff; /* Fundal albastru pentru header */
  color: white;
  padding: 12px;
  text-align: left;
  font-weight: bold;
}

.league-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* Linie discretă între rânduri */
  color: #333;
}

.league-table tr:nth-child(even) {
  background-color: #f2f2f2; /* Fundal gri deschis pentru rânduri pare */
}

/* Evidențierea echipelor implicate în eveniment */
.highlight-team {
  background-color: #d1e7dd; /* Fundal verde deschis pentru echipele evenimentului */
}

/* Responsivitate pentru ecrane mici */
@media (max-width: 768px) {
  .league-table th, .league-table td {
    font-size: 0.9rem; /* Text mai mic pentru mobil */
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .league-table th, .league-table td {
    font-size: 0.8rem; /* Dimensiuni și mai mici pentru ecrane foarte mici */
    padding: 8px;
  }
  
  .league-table tbody {
    display: block; /* Asigură că tabelul se rearanjează pe ecrane foarte mici */
  }
  
  .league-table tbody tr {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
  }
}

  
  
  /* Culoare pentru echipele implicate în eveniment */
  .highlight-team {
    background-color: #d1e7dd; /* Fundal diferit pentru echipele cu eveniment */
  }
  /* Stil pentru secțiunea de linkuri */
.link-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Spațiu între carduri */
  margin: 20px 0; /* Spațiu deasupra și dedesubtul secțiunii */
}

.link-card {
  flex: 1 1 300px; /* Fiecare card va ocupa minim 300px, dar va crește pentru a umple spațiul disponibil */
  background-color: #fff; /* Fundal alb */
  border-radius: 8px; /* Colțuri rotunjite */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Umbră pentru efect de adâncime */
  padding: 20px; /* Spațiu interior */
  text-align: center; /* Centrarea textului */
  transition: transform 0.3s; /* Efect de tranziție la hover */
}

.link-card:hover {
  transform: translateY(-5px); /* Levitare ușoară la hover */
}

.link-card a {
  text-decoration: none; /* Elimină sublinierea */
  color: #007bff; /* Culoarea textului linkului */
  font-weight: bold; /* Îngroșează textul */
}

.link-card a:hover {
  color: #0056b3; /* Culoare mai închisă la hover */
}

  
  /* Responsivitate */
  @media (max-width: 600px) {
    .event-details {
      padding: 15px; /* Spațiu mai mic pe dispozitive mobile */
    }
  
    .event-details h2 {
      font-size: 1.5rem; /* Dimensiune mai mică pentru titlu */
    }
  
    .event-details p {
      font-size: 0.9rem; /* Dimensiune mai mică pentru text */
    }
  
    .team-badge {
      width: 60px; /* Dimensiune mai mică pentru emblemele echipelor pe mobil */
      height: 60px; /* Dimensiune mai mică pentru emblemele echipelor pe mobil */
    }
  
    .league-table th, .league-table td {
      padding: 10px; /* Spațiu mai mic pentru celule pe mobil */
      font-size: 0.9rem; /* Dimensiune mai mică pentru text în tabel pe mobil */
    }
  }
  /* Stilizarea butonului Adaugă link */
/* Stilizarea butonului Adaugă link */
.add-link-button {
  background-color: #28a745; /* Verde atractiv */
  color: white;
  border: none;
  padding: 12px 24px; /* Spațiu mai mare */
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Adaugă tranziție la transformare */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Umbră mai puternică */
  margin-bottom: 20px;
}

.add-link-button:hover {
  background-color: #218838; /* O nuanță mai închisă la hover */
  transform: scale(1.05); /* Crește dimensiunea butonului la hover */
}

/* Stilizarea butonului Șterge link */
.delete-link-button {
  background-color: #dc3545; /* Roșu */
  color: white;
  border: none;
  padding: 10px 20px; /* Spațiu mai mare */
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Adaugă tranziție la transformare */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Umbră mai puternică */
  margin-left: 10px;
}

.delete-link-button:hover {
  background-color: #c82333; /* O nuanță mai închisă la hover */
  transform: scale(1.05); /* Crește dimensiunea butonului la hover */
}

/* Pentru a centra butoanele */
.button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}
.tv-channels-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* Spatiere intre canale */
}

.tv-channel {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.tv-channel:hover {
  transform: scale(1.05); /* Efet de hover */
}

.tv-channel-logo {
  width: 50px; /* Poate fi ajustat în funcție de dimensiunea dorită */
  height: auto;
  margin-right: 1rem; /* Spațiu între logo și nume */
}
.european-times {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}



h2, h3 {
  color: #333;
}

.country-time-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.country-time-table th, .country-time-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.country-time-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.country-time-table tr:nth-child(even) {
  background-color: #f9f9f9;
}



  